import React from 'react'
import { graphql } from 'gatsby'
import Link from '../components/Link'
import Layout from '../components/Layout'
import Image from '../components/Image'
import SuperheroBox from '../components/SuperheroBox'
import PulloutBox from '../components/PulloutBox'

// Returns a random integer between min (included) and max (excluded)
function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min
}

// How to add more index images:
// 1. Add an entry in `imageProps`
// 2. Update graphql query
const imageProps = [
  { title: 'Telemegaphone', url: '/work/telemegaphone' },
  { title: 'Megaphonebooth', url: '/work/megaphonebooth' },
  { title: 'Eating E.T.', url: '/work/eating-et' },
  { title: 'Parascope', url: '/work/parascope' },
]

const IndexPage = ({ data }) => {
  const i = getRandomInt(0, imageProps.length)
  const imageData = data[`image${i}`]
  const imageUrl = imageProps[i].url
  const imageTitle = imageProps[i].title

  const seo = {
    image: imageData,
  }

  return (
    <Layout>
      <article>
        <SuperheroBox>
          <Link to={imageUrl}>
            <Image data={imageData} title={imageTitle} />
          </Link>
        </SuperheroBox>
        <PulloutBox>
          <p>
            <strong>
              <em>We craft beautiful action spaces</em>
            </strong>
          </p>
        </PulloutBox>
      </article>
    </Layout>
  )
}

// TODO: Move fragments to own file?
export const superheroImage = graphql`
  fragment superheroImage on File {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid
      }
    }
    publicURL
    extension
  }
`

// Used to create images for meta fields such as `og:image`
export const seoImage = graphql`
  fragment seoImage on File {
    childImageSharp {
      resize(width: 1600) {
        src
      }
    }
  }
`

export const indexQuery = graphql`
  query IndexQuery {
    image0: file(
      relativePath: { eq: "unsworn/telemegaphone/hike_2008/tmd_hike_steep.jpg" }
    ) {
      ...superheroImage
    }
    image1: file(
      relativePath: { eq: "unsworn/megaphonebooth/helsinki/mb_esplanadi.jpg" }
    ) {
      ...superheroImage
    }
    image2: file(
      relativePath: { eq: "unsworn/eating_et/pufendorf/eating_et.jpg" }
    ) {
      ...superheroImage
    }
    image3: file(
      relativePath: { eq: "unsworn/parascope/parascope_mobilia_16x9.jpg" }
    ) {
      ...superheroImage
    }
  }
`

export default IndexPage
